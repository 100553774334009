import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { Encriptacion } from '../classes/encriptacion';

@Injectable({
  providedIn: 'root'
})
export class CursosService {
  private baseURL: string = AppSettings.API_ENDPOINT;

  constructor(
    private http: HttpClient
  ) { }

  getDetalles(idtrcurscat: string){
      let enc = new Encriptacion();
      let id_empresa = enc.encriptar(AppSettings.id_empresa.toString());
      let request = {
          id_empresa: id_empresa,
          curso: idtrcurscat
      };

      return this.http.post(this.baseURL + '/cursos/find', request);
  }

  getByCategoria(request){
    return this.http.post(this.baseURL + '/cursos/find/by/categoria', request);
  }

}
