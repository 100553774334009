import { environment } from "src/environments/environment";

export class RedireccionTPV {
    public static redireccionarTPV_BAC(id_pedido: number){
        let mapForm = document.createElement("form");
        mapForm.target = "Redsys";
        mapForm.method = "POST"; // or "post" if appropriate
        mapForm.action = environment.url_tpv_redsys;
    
        let mapInput = document.createElement("input");
        mapInput.type = "hidden";
        mapInput.name = "pedido";
        mapInput.value = id_pedido.toString();
        mapForm.appendChild(mapInput);
    
        document.body.appendChild(mapForm);
    
        let ventana = window.open("", "Redsys");
    
        if (ventana) {
            mapForm.submit();
        } else {
            alert('You must allow popups for this map to work.');
        }
    }
}
