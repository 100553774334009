import { DatepickerFechaNacimientoComponent } from './components/formulario_registro/datepicker-fecha-nacimiento/datepicker-fecha-nacimiento.component';
import { Paso1Component } from './components/formulario_registro/pasos/paso1/paso1.component';
import { InscripcionComponent } from './components/inscripcion/inscripcion.component';
import { LoginComponent } from './components/login/login.component';
import { WaitingSpinnerComponent } from './components/waiting-spinner/waiting-spinner.component';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';


//Material//
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
///-----------///

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { HomeComponent } from './components/home/home.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CardCategoriaComponent } from './components/card-categoria/card-categoria.component';
import { ListadoCursosComponent } from './components/listado-cursos/listado-cursos.component';
import { CardCursoComponent } from './components/card-curso/card-curso.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Paso2Component } from './components/formulario_registro/pasos/paso2/paso2.component';
import { PasoConfirmacionComponent } from './components/formulario_registro/pasos/paso-confirmacion/paso-confirmacion.component';
import { DetalleCursoComponent } from './components/detalle-curso/detalle-curso.component';
import { CestaComponent, CestaDialog } from './components/cesta/cesta.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ContenidoCestaComponent } from './components/cesta/contenido-cesta/contenido-cesta.component';
import { ItemCestaComponent } from './components/cesta/contenido-cesta/item-cesta/item-cesta.component';
import { BotonAnadirCestaComponent } from './components/card-curso/boton-anadir-cesta/boton-anadir-cesta.component';
import { DetallesItemCestaComponent } from './components/cesta/contenido-cesta/detalles-item-cesta/detalles-item-cesta.component';
import { BurguerComponent } from './components/nav/burguer/burguer.component';
import { PedidoRealizadoComponent } from './components/pedido-realizado/pedido-realizado.component';
import { BuscadorDescuentosComponent } from './components/buscador-descuentos/buscador-descuentos.component';
import { AvisoLegalComponent } from './components/aviso-legal/aviso-legal.component';
import { CondicionesGeneralesComponent } from './components/condiciones-generales/condiciones-generales.component';
import { ContactoBottomSheetComponent } from './components/footer/contacto-bottom-sheet/contacto-bottom-sheet.component';
import { FormaPagoComponent } from './components/forma-pago/forma-pago.component';
import { SelectorFormaPagoComponent } from './components/forma-pago/selector-forma-pago/selector-forma-pago.component';
import { ItemFormaPagoComponent } from './components/forma-pago/selector-forma-pago/item-forma-pago/item-forma-pago.component';
import { PedidoRealizadoTPVComponent } from './components/pedido-realizado-tpv/pedido-realizado-tpv.component';
import { TPVOKNOKComponent } from './components/tpv-ok-nok/tpv-ok-nok.component';
import { AppSettings } from './app-settings';
import { AvisoWebComponent } from './components/aviso-web/aviso-web.component';


registerLocaleData(localeEs);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + AppSettings.VERSION);
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    WaitingSpinnerComponent,
    HomeComponent,
    CardCategoriaComponent,
    ListadoCursosComponent,
    CardCursoComponent,
    LoginComponent,
    InscripcionComponent,
    Paso1Component,
    DatepickerFechaNacimientoComponent,
    Paso2Component,
    PasoConfirmacionComponent,
    DetalleCursoComponent,
    CestaComponent,
    CestaDialog,
    ContenidoCestaComponent,
    ItemCestaComponent,
    BotonAnadirCestaComponent,
    DetallesItemCestaComponent,
    BurguerComponent,
    PedidoRealizadoComponent,
    BuscadorDescuentosComponent,
    AvisoLegalComponent,
    CondicionesGeneralesComponent,
    CondicionesGeneralesComponent,
    ContactoBottomSheetComponent,
    FormaPagoComponent,
    SelectorFormaPagoComponent,
    ItemFormaPagoComponent,
    PedidoRealizadoTPVComponent,
    TPVOKNOKComponent,
    AvisoWebComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SnotifyModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,
    MatStepperModule,
    MatDatepickerModule,
    MatListModule,
    MatDialogModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatMenuModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatRadioModule,
    MatRippleModule
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    {
      provide: LOCALE_ID,
      useValue: 'es'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
