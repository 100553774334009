import { StepperService } from 'src/app/services/formulario_registro/stepper.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Step } from 'src/app/enum/step.enum';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-inscripcion',
  templateUrl: './inscripcion.component.html',
  styleUrls: ['./inscripcion.component.css']
})
export class InscripcionComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper') private stepper: MatStepper;


  public formInscripcion = new FormGroup({});

  public isLinear = false;

  public loggedin: boolean = null;



  constructor(
    private stepperServ: StepperService,
    private Auth: AuthService,
    ) {
      
      this.Auth.getAuthStatus().subscribe(res => {
        this.loggedin = res
      });
  }
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.stepperServ.step.subscribe(data => this.handleStepChange(data));
    this.stepperServ.index.subscribe(index => this.handleStepIndexChange(index));
  }

  handleStepChange(data){
   switch(data){
     case Step.Anterior:{
      this.stepperAnterior(); 
      break;
     }
     case Step.Siguiente:{
       this.stepperSiguiente();
     }
     default:{
       return false;
     }
   }
  }

  handleStepIndexChange(index){
    if(this.stepper != undefined){
      this.stepper.selectedIndex = index;
    }
  }

  stepperAnterior(){
    this.stepper.previous();
  }
  stepperSiguiente(){
    this.stepper.next();
  }

}
