<footer class="footer">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-md-3">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6" style="text-align: left;">
                            {{'colaboracion con' | translate}}:
                        </div>
                        <div class="col-md-6 logo-bac">
                            <img src="{{url_logo_BAC}}" alt="">
                        </div>
                    </div>
                </div>
                  
            </div>
            <div class="col-md-6">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <a [routerLink]="['aviso-legal']">
                                    {{'aviso legal' | translate}}
                                </a>
                            </div>
                            <div class="col-md-4">
                                <a [routerLink]="['condiciones-generales']">
                                    {{'condiciones generales' | translate}}
                                </a>
                            </div>
                            <div class="col-md-4">

                                <a href="javascript:;" (click)="abrirContacto()" class="first-capitalize">
                                    {{'contacto' | translate}}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</footer>