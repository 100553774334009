import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { Encriptacion } from '../classes/encriptacion';
import { CodigoDescuento } from '../classes/entity-data-models/codigo-descuento';
import { ResponseApi } from '../classes/response-api';
import { CestaService } from './cesta.service';
import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';

@Injectable({
  providedIn: 'root'
})
export class CodigosDescuentoService {

  private baseUrl = AppSettings.API_ENDPOINT;
  private enc = new Encriptacion();

  private _descuento = new BehaviorSubject<CodigoDescuento>(new CodigoDescuento());
  private _descuento_encontrado = new BehaviorSubject<boolean>(false);
  public descuento_encontrado = this._descuento_encontrado.asObservable();
  private cesta: ItemCesta[]         = [];
 

  descuento = this._descuento.asObservable();

  constructor(
    private http: HttpClient,
    private sNotify: SnotifyService,
    private translate: TranslateService,
    private cestaSrv: CestaService,
    ) { }
    
  find(codigo){
      let id_empresa = this.enc.encriptar(AppSettings.id_empresa.toString());
      let cestaStr = sessionStorage.getItem(AppSettings.CESTA_VAR);
      let cursosencesta = []
      this.cesta = [];
      if(cestaStr != null){
        this.cesta = JSON.parse(cestaStr);
        //bucle for para recoller na "cestacursos" so o idtrcurscat para que a peticion nn sexa tan pesada
        this.cesta.forEach(curso => {
          cursosencesta.push(curso.curso.idtrcurscat)
        });
      }else{
        this.cesta = [];
      }
      let request = {
        id_empresa : id_empresa,
        codigo: codigo,
        //aqui será o arrai de cestas
        cesta: cursosencesta
      };
      return this.http.post(this.baseUrl + '/codigos_descuento/find', request); 
  }

  updateDescuento(codigo){
    this._descuento.next(null);
    this.find(codigo).subscribe((res:ResponseApi) => {
      if(res != null && res != undefined){
        this._descuento.next(res.data.codigo_descuento);
        if(res.data.codigo_descuento.general==1){
          this.translate.get('descuento encontrado').subscribe((translation: string) => {
            this.cestaSrv.aplicarDescuentoCesta(this._descuento.value);
            this.sNotify.success(translation, AppSettings.sNotifyCommonConf);
          });
          this._descuento_encontrado.next(true);
        }else{
          //ver el descuento excl
          if(res.data.CursosAplicables.length>=1){
            res.data.CursosAplicables.forEach(datocodigodescuento => {
              if(datocodigodescuento.descuento_excl){
                console.log(datocodigodescuento.descuento_excl)
              }
            });
            //
            this.translate.get('descuento encontrado').subscribe((translation: string) => {
              this.cestaSrv.aplicarDescuentoCurso(this._descuento.value, res.data.CursosAplicables);
              this.sNotify.success(translation, AppSettings.sNotifyCommonConf);
            });
            this._descuento_encontrado.next(true);
          }
        }
      }else{
        this.translate.get('descuento no encontrado').subscribe((translation: string) => {
          this.sNotify.error(translation, AppSettings.sNotifyRightBottomConf);
        });
        this._descuento_encontrado.next(false);
      }
    });
  }

  eliminarDescuento(){
    this._descuento.next(null);
  }
}
