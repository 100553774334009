import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonFunctions } from 'src/app/classes/common-functions';
import { Encriptacion } from 'src/app/classes/encriptacion';
import { CodigoDescuento } from 'src/app/classes/entity-data-models/codigo-descuento';
import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';
import { CestaService } from 'src/app/services/cesta.service';
import { CodigosDescuentoService } from 'src/app/services/codigos-descuento.service';

@Component({
  selector: 'app-item-cesta',
  templateUrl: './item-cesta.component.html',
  styleUrls: ['./item-cesta.component.css'],
  animations: [
    trigger('eliminar', [
      // ...
      state('void', style({
        opacity: 1
      })),
      state('eliminado', style({
        opacity: 0,
        'background-color' : '#000000',
        'margin-left' : '100px'
      })),
      transition('void => eliminado', [
        animate('0.5s')
      ])
    ]),
  ]
})
export class ItemCestaComponent implements OnInit, OnChanges {
  @Input() public item: ItemCesta = null;
  @Input() public item_seleccionado: ItemCesta = null;
  @Input() public error: boolean = false;

  @Output() private eliminando_item = new EventEmitter<ItemCesta>();
  @Output() private seleccionar_item = new EventEmitter<ItemCesta>();

  public precio: number = null;
  public eliminar: boolean = false;
  private enc = new Encriptacion();

  constructor(
    private cestaSrv: CestaService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.item?.codigo_descuento != null){
          this.calcularPrecio();
      }
  }

  ngOnInit(): void {
    this.cestaSrv.getCodigoAplicado().subscribe((des: CodigoDescuento) => {
        if(this.item != null && des != null){
          this.item.codigo_descuento = des;
          this.calcularPrecio();
        }
    });

  }

  calcularPrecio(){
   // console.log(this.item)
    if(this.item.codigo_descuento.general==1){
      let precio:number        = +this.item?.curso.precio_seleccionado;
      let cantidad:number      = this.item?.codigo_descuento.cantidad;
      let tipoDescuento:number = +this.enc.desencriptar(this.item?.codigo_descuento.tipo);

      this.precio = CommonFunctions.obtenerPrecioDescontado(precio, cantidad, tipoDescuento);
    }else{
      if(this.item.cursosAplicables[0]){
        if(this.item.cursosAplicables[0].descuento_excl){

          let precio:number        = +this.item?.curso.precio_seleccionado;
          let cantidad:number      = this.item?.cursosAplicables[0].descuento_excl;
          let tipoDescuento:number = +this.item?.cursosAplicables[0].tipodescuento_excl;

          this.precio = CommonFunctions.obtenerPrecioDescontado(precio, cantidad, tipoDescuento);
        }else{

          let precio:number        = +this.item?.curso.precio_seleccionado;
          let cantidad:number      = this.item?.codigo_descuento.cantidad;
          let tipoDescuento:number = +this.enc.desencriptar(this.item?.codigo_descuento.tipo);

          this.precio = CommonFunctions.obtenerPrecioDescontado(precio, cantidad, tipoDescuento);
        }
      }
  }
  }

  eliminarItem(){
    this.eliminar = true;
    this.eliminando_item.emit(this.item);
  }

  onEliminarDoneEvent(event: AnimationEvent){
    if(event.toState === 'eliminado'){
      this.cestaSrv.eliminarItem(this.item);
    }
  }

  seleccionarItem(){
    this.seleccionar_item.emit(this.item);
  }


}
