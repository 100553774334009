import { PedidosService } from 'src/app/services/pedidos.service';
import { CestaErrors } from 'src/app/classes/entity-data-models/cesta-errors';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { AppSettings } from 'src/app/app-settings';
import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';
import { ResponseApi } from 'src/app/classes/response-api';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CestaService } from 'src/app/services/cesta.service';
import { ICestaError } from 'src/app/interfaces/i-cesta-error';
import { ErrorService } from 'src/app/services/error/error.service';
import { Pedido } from 'src/app/classes/entity-data-models/pedido';
import { WaitingSpinnerService } from '../waiting-spinner/service/waiting-spinner.service';

@Component({
  selector: 'app-cesta',
  templateUrl: './cesta.component.html',
  styleUrls: ['./cesta.component.css']
})
export class CestaComponent implements OnInit {

  public cesta: ItemCesta[] = [];

  constructor(
    private cestaServ: CestaService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.cestaServ.get().subscribe((res: ItemCesta[]) => {
      this.cesta = res;
    });

    this.cestaServ.cerrar_modal.subscribe(
      (res: boolean) => {
        if(res){
          this.dialog.closeAll();
        }
      }
    );
  }

  abrirCesta(){
    this.dialog.open(CestaDialog)
  }

}

@Component({
  selector: 'app-cesta-dialog',
  templateUrl: 'cesta-dialog.html',
  styleUrls: ['./cesta.component.css']
})
export class CestaDialog implements OnInit{

  public importe_total: number = 0;
  public importe_descontado: number = 0;
  public importe_final: number = 0;

  public loggedin: boolean = false;

  private i18n_cerrar: string = 'cerrar';

  public errores_cesta: CestaErrors = new CestaErrors();

  public waiting_activo: boolean = false;

  constructor(
    private cestaSrv: CestaService,
    private Auth: AuthService,
    private router: Router,
    private sNotify: SnotifyService,
    private translate: TranslateService,
    private ErrorSrv: ErrorService,
    private pedidosSrv: PedidosService,
    private waiting_spinner: WaitingSpinnerService
  ){
    
  }

  ngOnInit(): void {
    this.Auth.authStatus.subscribe((res: boolean) => {
      this.loggedin = res;

      this.translate.get('necesita logearse').subscribe((translation: string) => {
          let error: ICestaError = {
            id :'login',
            msg : translation
          };

          this.errores_cesta.addError(error);

          if(this.loggedin) this.errores_cesta.removeError(error);;

      });

    });

    this.cestaSrv.getItemsErroneos().subscribe((items: ItemCesta[]) => {
      let error: ICestaError = {
        id :'errores_cesta',
        msg : ''
      };

      this.errores_cesta.removeError(error);

      if(items.length > 0){
        this.translate.get('errores elementos cesta', {'num_errores' : items.length}).subscribe((translation: string) => {
          error.msg = translation;
          this.errores_cesta.addError(error);
        });
      }
    });

    this.cestaSrv.getImporteTotal().subscribe((importe:number) => this.importe_total = importe);
    this.cestaSrv.getImporteDescontado().subscribe((importe:number) => this.importe_descontado = importe);
    this.cestaSrv.getImporteFinal().subscribe((importe:number) => this.importe_final = importe);

    this.translate.get(this.i18n_cerrar).subscribe((translation: string) => this.i18n_cerrar = translation);

    this.waiting_spinner.activado.subscribe(val => this.waiting_activo = val);
  }

  comprar(){
    this.cestaSrv.cerrarModal();
    this.router.navigateByUrl('seleccion-forma-pago');
  }


  public redireccionarLogin(){
    this.cestaSrv.cerrarModal();
    this.cestaSrv.activarAutoApertura();
    this.router.navigateByUrl(AppSettings.LOGIN_ROUTE);
  }


  public infoErrores(){
    let errors: ICestaError[] = this.errores_cesta.errors;
    let msg_errors: string = '';
    
    errors.forEach((e) => {
      msg_errors += '<div class="container"><div class="row error-cesta-texto"><div class="col-md-12" style="color: #FFFFFF;">' + e.msg + '</div></div></div>';
    });

    this.sNotify.error('', AppSettings.getInfoConf([
      {text: this.i18n_cerrar, action: () => null}
    ], msg_errors));
  }

}
