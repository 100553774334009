<form [formGroup]="form">

<div class="container">
    <div class="row align-items-center">
        <div class="col-xl-12">
            <label class="first-capitalize label-detalles-cesta">{{'modalidad' | translate}}</label>
            <mat-radio-group formControlName="id_modalidad" class="radio-group-horizontal" (change)="submit()">
                <mat-radio-button *ngFor="let modalidad of item.curso.modalidades" [value]="modalidad.id" class="radio-button">
                    <span class="first-capitalize input-detalles-cesta">{{modalidad.nombre}}</span>
                </mat-radio-button>
            </mat-radio-group>

        </div>
    </div>
    <div class="row fila-error">
        <div class="col-xl-12">
            <mat-error *ngIf="form.controls.id_modalidad.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
        </div>
    </div>

    <div class="row align-items-center">
        <div class="col-xl-12">
            <mat-form-field appearance="fill" class="select-detalles">
                <mat-label>{{'convocatoria' | translate}}</mat-label>
                <mat-select formControlName="convocatoria" (selectionChange)="submit()" class="input-detalles-convocatorias">
                  <mat-option [value]="convocatoria.fechainicio + convocatoria.fechafin" *ngFor="let convocatoria of convocatorias">{{formatearFecha(convocatoria.fechainicio)}} - {{formatearFecha(convocatoria.fechafin)}}</mat-option>
                </mat-select>

            </mat-form-field>

        </div>
    </div>
    <div class="row fila-error">
        <div class="col-xl-12">
            <mat-error *ngIf="form.controls.convocatoria.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
        </div>
    </div>

    <div class="row align-items-center">
        <div class="col-xl-12">
            <mat-form-field class="select-detalles">
                <mat-label>{{'categoria diploma' | translate}}</mat-label>
                <input type="text"
                       matInput
                       [formControl]="form.controls.categoria_diploma"
                       [matAutocomplete]="auto"
                       class="input-detalles-cesta"
                       [readonly]="item.curso.es_cfc"
                       >
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let categoria_seleccionable of filtered_categorias | async" [value]="categoria_seleccionable">
                    {{categoria_seleccionable}}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>
    </div>
    <div class="row fila-error">
        <div class="col-xl-12">
            <mat-error *ngIf="form.controls.categoria_diploma.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
        </div>
    </div>
    <div class="row">
        <button mat-raised-button color="primary-aetesys" class="boton-full-width mat-elevation-z6" (click)="submit()" [disabled]="form.status == 'INVALID'">
            <span class="first-capitalize">{{'modificar' | translate}}</span>
        </button>
    </div>
</div>
</form>
