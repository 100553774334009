
<div class="container full-height">
    <div class="row justify-content-center align-items-center full-height">
        <div class="col-md-8">
            <mat-card class="mat-elevation-z8">
                <mat-card-header>
                    <div mat-card-avatar><mat-icon>vpn_key</mat-icon></div>
                    <mat-card-title>{{'Login' | translate}}</mat-card-title>
                    <mat-card-subtitle>{{'subtitulo login' | translate}}</mat-card-subtitle>
                </mat-card-header>
                <mat-icon *ngIf="!loggedin" class="icon-large mat-secundary-aetesys mat-elevation-z3">lock</mat-icon>
                <mat-icon *ngIf="loggedin" class="icon-large mat-secundary-aetesys mat-elevation-z3">lock_open</mat-icon>
                <mat-card-content class="login-card-body">
                    <div class="container">
                        <form [formGroup]="form">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="input-text">
                                    <mat-label><span class="first-capitalize input-login">{{'usuario' | translate}}</span></mat-label>
                                    <input matInput type="text" formControlName="usuario" class="input-login" placeholder="{{'placeholder usuario login' | translate}}">
                                </mat-form-field>
                                <mat-error *ngIf="firstSubmit && form.controls.usuario.errors?.required" [innerHTML]="'error usuario required' | translate"></mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="input-text">
                                    <mat-label><span class="first-capitalize input-login">{{'contrasena' | translate}}</span></mat-label>
                                    <input matInput type="password" formControlName="contrasena" class="input-login">
                                </mat-form-field>
                                <mat-error *ngIf="firstSubmit && form.controls.contrasena.errors?.required" [innerHTML]="'error contrasena required' | translate"></mat-error>
                            </div>
                        </div>
                        </form>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <div class="row">
                        <div class="col-md-6">
                            <button mat-raised-button color="primary-aetesys" class="boton-full-width" *ngIf="!loggedin" [disabled]="disabled" (click)="submit()"><span class="first-capitalize">{{'acceder' | translate}}</span></button>
                        </div>
                        <div class="col-md-6">
                            <a routerLink="/inscripcion">
                                <button mat-raised-button color="secundary-aetesys" class="boton-full-width" *ngIf="!loggedin"><span class="first-capitalize">{{'registrarse' | translate}}</span></button>
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-progress-bar mode="indeterminate" *ngIf="loggedin" color="warn"></mat-progress-bar>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
