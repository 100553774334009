import { CestaService } from 'src/app/services/cesta.service';
import { FondoService } from 'src/app/services/fondo.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Encriptacion } from 'src/app/classes/encriptacion';
import { CodigoDescuento } from 'src/app/classes/entity-data-models/codigo-descuento';
import { Curso } from 'src/app/classes/entity-data-models/curso';
import { ResponseApi } from 'src/app/classes/response-api';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CursosService } from 'src/app/services/cursos.service';
import { CommonFunctions } from 'src/app/classes/common-functions';
import { ConvocatoriasService } from 'src/app/services/convocatorias.service';
import { DateFormat } from 'src/app/classes/date-format';
import { Convocatoria } from 'src/app/classes/entity-data-models/convocatoria';
import { CategoriascursosService } from 'src/app/services/categoriascursos.service';
import { CategoriaCurso } from 'src/app/classes/entity-data-models/categoria-curso';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ItemMenuCurso } from 'src/app/classes/entity-data-models/item-menu-curso';
import { TipoLink } from 'src/app/enum/tipo-link.enum';
import { MenuCursosService } from 'src/app/services/menu-cursos.service';
import { Modalidad } from 'src/app/classes/entity-data-models/modalidad';
import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';

@Component({
  selector: 'app-detalle-curso',
  templateUrl: './detalle-curso.component.html',
  styleUrls: ['./detalle-curso.component.css']
})
export class DetalleCursoComponent implements OnInit, OnDestroy {

  public curso: Curso = null;
  public codigo_descuento: CodigoDescuento = null;
  public loggedin: boolean = false;

  public precio: number = null;
  public precio_descuento: number = null;

  public id_modalidad_seleccionada: number = null;

  private enc: Encriptacion = new Encriptacion();
  private translateSub: Subscription = null;
  private categoriaSub: Subscription = null;


  constructor(
    private activatedRoute: ActivatedRoute,
    private categoriasServ: CategoriascursosService,
    private cursosServ: CursosService,
    private fondoServ: FondoService,
    private Auth: AuthService,
    private Cesta: CestaService,
    private translate: TranslateService,
    private convocatoriasSrv: ConvocatoriasService,
    private menuCursosSrv: MenuCursosService
  ) { }

  ngOnDestroy(): void {
    if(this.translateSub != null){
      this.translateSub.unsubscribe();
    }
    if(this.categoriaSub != null){
      this.categoriaSub.unsubscribe();
    }

    this.fondoServ.setFondoDefault();
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
          let idtrcurscat: string = params['id'];

          this.categoriaSub = this.categoriasServ.categoriaCurso(idtrcurscat).subscribe((resApi: ResponseApi) => {
            if(resApi){
              let cat: CategoriaCurso = resApi.data.categoria;
              this.categoriasServ.setCategoriaSeleccionada(cat);

              this.translateSub = this.translate.get('cursos de').subscribe((translation:string) => {
                  let nombre_menu: string = translation + ' ' + cat?.nombre;
                  let itemMenu: ItemMenuCurso = new ItemMenuCurso(nombre_menu, 'cursos/' + cat.trcatcur, TipoLink.ROUTER_LINK);
                  this.menuCursosSrv.push(itemMenu);
              });

            }
          });

          this.cursosServ.getDetalles(idtrcurscat).subscribe(
            (res: ResponseApi) => {
                if(res != null){
                  this.curso = new Curso(res.data.curso);
                  this.id_modalidad_seleccionada = this.curso.modalidad_seleccionada.id;
                  this.fondoServ.setFondo(this.curso?.imagen_or);

                  if(res.data.codigos_descuento != null){
                      let arrCodDesc: CodigoDescuento[] = res.data.codigos_descuento;
                      this.codigo_descuento = arrCodDesc[0];

                      this.calcularImportes();

                  }

                  this.convocatoriasSrv.getByAcreditacion(this.curso.idacreditacion).subscribe((res: ResponseApi) => {
                    if(res != null){
                      this.curso.convocatorias = res.data.convocatorias;
                    }

                  });

                  let item_cesta: ItemCesta = this.Cesta.findItemByCurso(this.curso);
                  if(item_cesta != null){
                    this._cambiarModalidad(item_cesta.curso.modalidad_seleccionada.id);
                  }
                }
            }
          );
    });

    this.Auth.authStatus.subscribe(val => this.loggedin = val);

  }

  public anadirCarrito(){
    this.Cesta.insertarItem(this.curso, this.codigo_descuento);
  }

  public cambiarModalidad(id_modalidad: number){
      if(!this.existeCurso()){
        this._cambiarModalidad(id_modalidad);
      }
  }

  public calcularImportes(){
    if(this.codigo_descuento != null){
      let tipodescuento: number = +this.enc.desencriptar(this.codigo_descuento.tipo);
      let cantidad: number = this.codigo_descuento.cantidad;

      this.precio_descuento = CommonFunctions.obtenerPrecioDescuento(this.curso?.precio_seleccionado, cantidad, tipodescuento);
      this.precio = CommonFunctions.obtenerPrecioDescontado(this.curso?.precio_seleccionado, cantidad, tipodescuento);
    }
  }

  public existeCurso(): boolean{
    return this.Cesta.existeItem(this.curso);
  }

  public formatearFecha(fecha: string): string{
    let date = new Date(fecha);
    let format_date = new Intl.DateTimeFormat('es-ES').format(date);
    //console.log(format_date.toString() + "8");
    return format_date;
  }

  public seleccionarConvocatoria(convocatoria: Convocatoria){
    this.curso.convocatoria_seleccionada = convocatoria;
  }

  private _cambiarModalidad(id_modalidad: number){
    this.id_modalidad_seleccionada = id_modalidad;
    let modalidad_seleccionada: Modalidad = this.curso.modalidades.find((item: Modalidad) => item.id == id_modalidad);

    this.curso.setModalidadSeleccionada(modalidad_seleccionada);

    this.calcularImportes();
  }
}
