<div>
    <mat-horizontal-stepper *ngIf="!loggedin" [linear]="isLinear" #stepper>
        <mat-step>
            <ng-template matStepLabel> <span class="first-capitalize">{{'datos personales' | translate}}</span> </ng-template>
            <app-paso1></app-paso1>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel> <span class="first-capitalize">{{'credenciales' | translate}}</span> </ng-template>
            <app-paso2></app-paso2>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>{{'revisar datos' | translate}}</ng-template>
            <app-paso-confirmacion></app-paso-confirmacion>
        </mat-step>
    </mat-horizontal-stepper>
</div>
