<div class="container">
    <div class="row">
        <div class="col-md-12">
            <mat-accordion multi>
                <mat-expansion-panel [expanded]="datosPersonales != null">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span class="first-capitalize">{{'datos personales' | translate}}</span>
                      </mat-panel-title>
                      <mat-panel-description *ngIf="datosPersonales == null">
                        <span class="error error-message">{{'errores formulario' | translate}}</span> &nbsp; &nbsp; &nbsp; <a (click)="iraIndice(0)">Ver</a>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div *ngIf="datosPersonales != null">
                        <mat-list>
                            <div mat-subheader><mat-icon mat-list-icon>person</mat-icon> <span class="first-capitalize">{{'datos personales' | translate}}</span> </div>
                            <mat-list-item class="list-item">
                                <div mat-line class="list-line">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <span class="first-capitalize"><strong>{{'nombre' | translate}}:</strong> {{datosPersonales.nombre}} {{datosPersonales.apellidos}}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <span class="first-capitalize"><strong>{{'dni' | translate}}:</strong> {{datosPersonales.dni}}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <span class="first-capitalize"><strong>{{'sexo' | translate}}:</strong> {{(datosPersonales.sexo == 'V') ? 'Hombre' : 'Mujer'}}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <span class="first-capitalize"><strong>{{'fecha nacimiento' | translate}}:</strong> {{datosPersonales.nac_vista}}</span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <span class="first-capitalize"><strong>{{'nacionalidad' | translate}}:</strong> {{datosPersonales.nacionalidad}}</span>
                                                </div>
                                            </div>
                    
                                </div>
        
                            </mat-list-item>
        
                            <mat-divider></mat-divider>
                            
                            <div mat-subheader><mat-icon mat-list-icon>home</mat-icon> <span class="first-capitalize">{{'residencia' | translate}}</span></div>
                            <mat-list-item class="list-item">
        
                                <div mat-line class="list-line">
        
                                  <div class="row">
                                    <div class="col-sm-12">
                                        <span class="first-capitalize"><strong>{{'direccion' | translate}}:</strong> {{datosPersonales.direccion1}} - {{datosPersonales.codigopostal1}} {{datosPersonales.municipio1}} ({{datosPersonales.provincia1}})</span>
                                    </div>
                                  </div>
        
                                </div>
        
                            </mat-list-item>
        
                            <mat-divider></mat-divider>
                            
                            <div mat-subheader><mat-icon mat-list-icon>perm_phone_msg</mat-icon> <span class="first-capitalize">{{'contacto' | translate}}</span></div>
                            <mat-list-item class="list-item">
        
                                <div mat-line class="list-line">
        
                                  <div class="row">
                                    <div class="col-sm-12">
                                        <span class="first-capitalize"><strong>{{'telefono' | translate}} 1:</strong> {{datosPersonales.telefono1}}</span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12">
                                        <span class="first-capitalize"><strong>{{'telefono' | translate}} 2:</strong> {{(datosPersonales.telefono2 != null)? datosPersonales.telefono2 : '-'}}</span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12">
                                        <span class="first-capitalize"><strong>{{'email' | translate}}:</strong> {{datosPersonales.email}}</span>
                                    </div>
                                  </div>
        
                                </div>
        
                            </mat-list-item>
        
                            <mat-divider></mat-divider>
                            
                        </mat-list>
                    </div>
                </mat-expansion-panel>
        
            </mat-accordion>
        
        </div>
    </div>
    <div class="col-md-12">
        <button *ngIf="!spinner_activado" mat-raised-button (click)="submit()" color="primary-aetesys" class="first-capitalize boton-full-width boton-enviar" [disabled]="!formularioOK()">{{'enviar' | translate}}</button>
    </div>
</div>