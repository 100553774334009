import { TipoModalidad } from "src/app/enum/tipo-modalidad.enum";

export class Modalidad {
    public id: TipoModalidad = null;
    public nombre: string = null;

    constructor(id: TipoModalidad = null, nombre: string = null){
        this.id     = id;
        this.nombre = nombre;
    }

}
