import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { AppSettings } from 'src/app/app-settings';
import { AuthService } from '../auth/auth.service';
import { TokenService } from '../auth/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private router: Router,
    private Auth: AuthService,
    private Token: TokenService,
    private sNotify: SnotifyService,
    private translate: TranslateService
  ) { }

  commonHandler(error){
    this.sNotify.error(error.error.message, AppSettings.sNotifyCommonConf);
    if(error.status == 401){
      this.Auth.changeAuthStatus(false);
      this.Token.remove();
      this.router.navigateByUrl(AppSettings.LOGIN_ROUTE);
    }
  }

  errorEnvioCesta(){
    this.translate.get('error procesar pedido').subscribe((translation: string) => {
      this.sNotify.error(translation, AppSettings.sNotifyCommonConf);
    });
    
  }
}
