import { ICestaError } from 'src/app/interfaces/i-cesta-error';
import { ItemCesta } from './item-cesta';
export class CestaErrors {


    private _errors: ICestaError[] = [];

    constructor(){

    }


    public addError(error: ICestaError){
        this.errors.push(error);
    }

    public removeError(error: ICestaError){
        let indice: number = this.errors.map(function(e) { return e.id; }).indexOf(error.id); 
        let errors: ICestaError[] = this.errors;

        if(indice > -1) errors.splice(indice, 1);

        this.errors = errors;
    }

    /**
     * Getter errors
     * @return {ICestaError[] }
     */
	public get errors(): ICestaError[]  {
		return this._errors;
	}

    /**
     * Setter errors
     * @param {ICestaError[] } value
     */
	public set errors(value: ICestaError[] ) {
		this._errors = value;
	}

}
