import {Component, SimpleChanges, OnChanges, Output, EventEmitter, Input} from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { Moment } from 'moment';

@Component({
  selector: 'app-datepicker-fecha-nacimiento',
  templateUrl: './datepicker-fecha-nacimiento.component.html',
  styleUrls: ['./datepicker-fecha-nacimiento.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class DatepickerFechaNacimientoComponent implements OnChanges{
  @Input() defaultDate;
  @Output() fecha_nacimiento = new EventEmitter<object>();

  public fecha:FormControl = new FormControl('');

  public maxDate: Date;
  
  constructor() {
      this.fecha.valueChanges.subscribe(selectedDate => {
        
        if(!(selectedDate instanceof Date)){
          this.fecha_nacimiento.emit({
            formato_mysql: selectedDate.format('YYYY-MM-DD'),
            formato_vista: selectedDate.format('DD/MM/YYYY'),
          });/**/
        }
      });
      // Solo deja escojer fecha para mayores de 18.
      let now = new Date();
      let currentYear = now.getFullYear();
      let currentMonth = now.getMonth();
      let currentDate = now.getDate();
      this.maxDate = new Date(currentYear - 18,currentMonth,currentDate);

  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.defaultDate != undefined || this.defaultDate != null){
      let date = new Date(this.defaultDate);
      this.fecha.setValue(date);
    }
  }

}
