import { CodigoDescuento } from 'src/app/classes/entity-data-models/codigo-descuento';
import { Curso } from 'src/app/classes/entity-data-models/curso';
import { CursosService } from 'src/app/services/cursos.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResponseApi } from 'src/app/classes/response-api';
import { Encriptacion } from 'src/app/classes/encriptacion';
import { AppSettings } from 'src/app/app-settings';
import { MenuCursosService } from 'src/app/services/menu-cursos.service';
import { ItemMenuCurso } from 'src/app/classes/entity-data-models/item-menu-curso';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TipoLink } from 'src/app/enum/tipo-link.enum';
import { CategoriascursosService } from 'src/app/services/categoriascursos.service';
import { CategoriaCurso } from 'src/app/classes/entity-data-models/categoria-curso';
import { WaitingSpinnerService } from '../waiting-spinner/service/waiting-spinner.service';
import { FondoService } from 'src/app/services/fondo.service';

@Component({
  selector: 'app-listado-cursos',
  templateUrl: './listado-cursos.component.html',
  styleUrls: ['./listado-cursos.component.css']
})
export class ListadoCursosComponent implements OnInit, OnDestroy {
  public cursos: Curso[] = null;
  
  private codigosdescuento: CodigoDescuento[] = null;

  private enc = new Encriptacion();

  private translateSub: Subscription = null;
  private categoriaSub: Subscription = null;

  constructor(
    private rutaActiva: ActivatedRoute,
    private categoriasServ: CategoriascursosService,
    private cursosServ: CursosService,
    private menuCursosSrv: MenuCursosService,
    private translate: TranslateService,
    private waiting_spinner: WaitingSpinnerService,
    private fondoSrv: FondoService
  ) { }

  ngOnDestroy(): void {
    if(this.translateSub != null){
      this.translateSub.unsubscribe();
    }
    if(this.categoriaSub != null){
      this.categoriaSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.rutaActiva.params.subscribe(params =>{
      let categoria = params['categoria'];

      if(categoria != null){
        let request = {
          categoria: categoria,
          id_empresa: this.enc.encriptar(AppSettings.id_empresa.toString())
        };

        this.waiting_spinner.activar();
        this.cursosServ.getByCategoria(request).subscribe(
          (response: ResponseApi) => {
            this.waiting_spinner.desactivar();
            this.cursos = response?.data.cursos;
            this.codigosdescuento = response?.data.codigos_descuento;
          },
          error => this.waiting_spinner.desactivar()
        );

        this.categoriaSub = this.categoriasServ.findByCatcur(categoria).subscribe((resApi: ResponseApi) => {
          
          if(resApi != null){

            let cat: CategoriaCurso = resApi.data.categoria;
            
            this.categoriasServ.setCategoriaSeleccionada(cat);
            
            this.translateSub = this.translate.get('cursos de').subscribe((translation:string) => {
                let nombre_menu: string = translation + ' ' + cat?.nombre;
                let itemMenu: ItemMenuCurso = new ItemMenuCurso(nombre_menu, 'cursos/' + categoria, TipoLink.ROUTER_LINK);
                this.menuCursosSrv.push(itemMenu);
            });

          }

        });

        
      }
    });

    this.fondoSrv.setFondoDefault();
  }

  public getDescuento(idtrcurscat: number){
      let cod: CodigoDescuento = null;
      
      cod = this.codigosdescuento?.find(val => val.general == 1);

      if(cod == undefined){
        cod = this.codigosdescuento?.find(val => val.idtrcurso == idtrcurscat);
      }

      return (cod == undefined) ? null : cod;
  }

}
