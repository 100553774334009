import { TokenService } from 'src/app/services/auth/token/token.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = AppSettings.API_ENDPOINT;

  private loggedIn = new BehaviorSubject(<boolean> null);
  authStatus = this.loggedIn.asObservable();


  constructor(
    private http: HttpClient,
    private Token: TokenService,
    private router: Router
    ) {
    }

  login(data) {
    return this.http.post(this.baseUrl + '/auth/login', data);
  }
  logout() {
    let request = {
      token: this.Token.get(),
      admin: false
    };
    
    return this.http.post(this.baseUrl + '/auth/logout', request);
  }
  changeAuthStatus(value: boolean) {
    this.loggedIn.next(value);
  }
  getAuthStatus():Observable<any>{
    return this.loggedIn.asObservable();
  }
  check(){
  
    this.Token.checkToken().subscribe(
      (res: any) => {
        //console.log(res)
      },
      error => {
        if(error.status == 401){
          this.router.navigateByUrl(AppSettings.LOGIN_ROUTE);
        }
      }
    );
  }
}
