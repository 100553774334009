import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { AppSettings } from 'src/app/app-settings';
import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';
import { CestaService } from 'src/app/services/cesta.service';
import { CodigosDescuentoService } from 'src/app/services/codigos-descuento.service';

@Component({
  selector: 'app-contenido-cesta',
  templateUrl: './contenido-cesta.component.html',
  styleUrls: ['./contenido-cesta.component.css'],

})
export class ContenidoCestaComponent implements OnInit {
  @ViewChild('detalles') detalles: ElementRef;

  public cesta_compra: ItemCesta[]    = [];

  //items_erroneos es unn array con los items que necesitan revisión por parte del usuario
  public items_erroneos: ItemCesta[]  = [];

  //itemSeleccionado es el item que se clckó para que se muestre el detalle del mismo
  public itemSeleccionado: ItemCesta  = null;

  //itemEliminando es el item que se capturó que esta siendo eliminado para controlar la animación de detalles
  public itemEliminando: ItemCesta = null;

  public tab_selected: number = 0;
  public i18n_detalles: string = '';

  public esta_abierto_buscador_descuentos: boolean = false;


  constructor(
    private cestaSrv: CestaService,
    private sNotify: SnotifyService,
    private translate: TranslateService,
    private descuentoSrv: CodigosDescuentoService
  ) { }

  ngOnInit(): void {
    this.cestaSrv.get().subscribe((res: ItemCesta[]) => {
        this.cesta_compra
        this.cesta_compra = res;
        if(this.cesta_compra?.length == 0){
          this.cestaSrv.cerrarModal();
        }
    });

    this.descuentoSrv.descuento_encontrado.subscribe((desc_encontrado: boolean) => {
      if(this.esta_abierto_buscador_descuentos && desc_encontrado){
        this.esta_abierto_buscador_descuentos = false;
      }
    });

    this.cestaSrv.getItemsErroneos().subscribe((items: ItemCesta[]) => this.items_erroneos = items);

    this.translate.get('detalles').subscribe((translation: string) => this.i18n_detalles = translation);
  }

  public seleccionarItem(item: ItemCesta){
    this.itemSeleccionado = (this.itemSeleccionado != item) ? item : null;
    if(this.itemEliminando?.curso.idtrcurscat == item.curso.idtrcurscat) this.itemSeleccionado = null;

    this.tab_selected = (this.itemSeleccionado == null) ? 0 : 1;

    this.esta_abierto_buscador_descuentos = false;
  }


  public actualizarItemCesta(item: ItemCesta){
    let index: number = this.cesta_compra.findIndex((x: ItemCesta) => x.curso.idtrcurscat == item.curso.idtrcurscat);
    this.cesta_compra[index] = new ItemCesta(item.curso, item.codigo_descuento);

    if(this.cestaSrv.set(this.cesta_compra)){
      this.translate.get('curso modificado exito', {curso: item.curso.nombre}).subscribe((msg: string) => {
        this.sNotify.success(msg, AppSettings.sNotifyCommonConf);
        this.sNotify.warning("Vuelva a aplicar el codigo descuento");
      });
    }
  }

  public esErroneo(item_test: ItemCesta){
    let itemsErr: ItemCesta[] = this.items_erroneos.filter((item: ItemCesta) => item.curso.idtrcurscat == item_test.curso.idtrcurscat);
    return (itemsErr.length > 0);
  }

  public setEliminandoItem(item: ItemCesta){
    this.itemEliminando = item;
  }

  public onSelectedTabChange($event){
    this.tab_selected = $event.index;
    if($event.index === 0){
      this.itemSeleccionado = null;
    }
  }

  public toggleBuscadorDescuentos(){
    this.esta_abierto_buscador_descuentos = !this.esta_abierto_buscador_descuentos;
    if(this.esta_abierto_buscador_descuentos){
      this.tab_selected = 0;
    }
  }

}
