import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { Pedido } from '../classes/entity-data-models/pedido';
import { ResponseApi } from '../classes/response-api';
import { EstadoPedido } from '../enum/estado-pedido.enum';
import { PedidosService } from './pedidos.service';

@Injectable({
  providedIn: 'root'
})
export class TimerTPVService {

  private timer_subscription: Subscription = null;
  private pedido_find_subscription: Subscription = null;
  private tiempo_refresco: number = 10000; //Milisegundos
  
  private _estado_pedido =  new BehaviorSubject(<number> null);
  public estado_pedido = this._estado_pedido.asObservable();

  constructor(
    private pedido_srv: PedidosService
  ) { }

  public startcheck(pedido: Pedido){
    this.timer_subscription = null;
    this.timer_subscription = timer(0, this.tiempo_refresco).subscribe(
      val => {
        this.checkPedido(pedido);
      }
    );

  }

  private checkPedido(pedido: Pedido){
    this.pedido_find_subscription = null;
    this.pedido_find_subscription = this.pedido_srv.find(pedido.id).subscribe(
      (res: ResponseApi) => {
        if(res != null){
          
          let pedido_check: Pedido = res.data.pedido;
          this._estado_pedido.next(pedido_check.id_estado);

          if(pedido_check.id_estado == EstadoPedido.ACEPTADO || pedido_check.id_estado == EstadoPedido.DENEGADO){
            this.timer_subscription.unsubscribe();
            this.pedido_find_subscription.unsubscribe();
          }
          
        }
      }
    );
  }

}
