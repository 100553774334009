import { Component, OnInit } from '@angular/core';
import { AvisosService } from 'src/app/services/avisos.service';

@Component({
  selector: 'app-aviso-web',
  templateUrl: './aviso-web.component.html',
  styleUrls: ['./aviso-web.component.css']
})
export class AvisoWebComponent implements OnInit {
  public aviso: any = {};

  constructor(
    private avisosServ: AvisosService
  ) { }

  hideAviso() {
    let AvisoElement = document.getElementById('ventanaaviso');
    AvisoElement.style.display = 'none';
  }

  ngOnInit(): void {
    let aviso = this.avisosServ.get();
    if (aviso) {
      aviso.subscribe(res => { this.aviso = res['data'] });
    } else {
      this.aviso = null;
    }
  }

}