import { Component, OnInit } from '@angular/core';
import { WaitingSpinnerService } from 'src/app/components/waiting-spinner/service/waiting-spinner.service';
import { StepperService } from 'src/app/services/formulario_registro/stepper.service';

@Component({
  selector: 'app-paso-confirmacion',
  templateUrl: './paso-confirmacion.component.html',
  styleUrls: ['./paso-confirmacion.component.css']
})
export class PasoConfirmacionComponent implements OnInit {

  public datosPersonales = {
    nombre: null,
    apellidos: null,
    dni: null,
    copia_dni: null,
    sexo: null,
    nacimiento: null,
    nacionalidad: null,
    profesion: null,
    telefono1: null,
    telefono2: null,
    email: null,
    direccion1: null,
    municipio1: null,
    provincia1: null,
    codigopostal1: null,
    contrasena: null,
  };
  public datosAcceso = [];
  public documentacion = [];

  public spinner_activado: boolean = false;

  constructor(
    private stepperSrv: StepperService,
    private waitingSpinner: WaitingSpinnerService
    ) { }

  ngOnInit(): void {
    this.stepperSrv.formDatosPersonales.subscribe(data => this.datosPersonales = data);
    this.waitingSpinner.activado.subscribe(val => this.spinner_activado = val);
  }

  iraIndice(indice){
    this.stepperSrv.setFirstSubmit(true);
    this.stepperSrv.irIndice(indice);
  }

  submit(){
    this.stepperSrv.enviarDatos();
  }

  formularioOK(){
    return this.stepperSrv.formularioOK();
  }

}
