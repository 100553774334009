import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';

@Injectable({
  providedIn: 'root'
})
export class NacionalidadesService {
  private baseUrl = AppSettings.API_ENDPOINT;

  private _nacionalidades = new BehaviorSubject(null);

  nacionalidades = this._nacionalidades.asObservable();

  constructor(private http:HttpClient) { }

  list() {
    return this.http.get(this.baseUrl + '/nacionalidades/list');
  }

  updateNacionalidades(){
    this.list().subscribe(
      data => this._nacionalidades.next(data),
      error => console.error(error)
    );
    
  }

}
