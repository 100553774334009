<div>


    <form [formGroup]="form" action="" method="POST" enctype="multipart/form-data" (ngSubmit)="onSubmit()">
        <div class="row align-items-end"><h4 class="first-capitalize">{{'datos personales' | translate}}</h4></div>
        <div class="row align-items-end">
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'nombre' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="nombre" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.nombre.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'apellido1' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="apellido1" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.apellido1.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'apellido2' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="apellido2" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.apellido2.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
        </div>
        <div class="row align-items-end">
            <div class="col-md-4">
                <mat-form-field class="input-text" appearance="fill">
                    <mat-label class="first-capitalize">{{'nacionalidad' | translate}}</mat-label>
                    <mat-select formControlName="nacionalidad" (selectionChange)="onChangeNacionalidad()">
                      <mat-option *ngFor="let nacionalidad of nacionalidades" [value]="nacionalidad.id">
                        {{nacionalidad.GENTILICIO_NAC}} | {{nacionalidad.PAIS_NAC}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.nombre.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text" style="text-transform: capitalize;" appearance="fill">
                    <mat-label class="first-capitalize">{{'sexo' | translate}}</mat-label>
                    <mat-select formControlName="sexo">
                      <mat-option [value]="'V'"> <span class="first-capitalize">{{'hombre' | translate}}</span> </mat-option>
                      <mat-option [value]="'H'"> <span class="first-capitalize">{{'mujer' | translate}}</span> </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="firstSubmit && form.controls.sexo.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <input type="hidden" formControlName="nacimiento">
                <app-datepicker-fecha-nacimiento [defaultDate]="fechaNacSel.formato_mysql"  (fecha_nacimiento)="addFecha($event)"></app-datepicker-fecha-nacimiento>
                <mat-error *ngIf="firstSubmit && form.controls.nacimiento.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
        </div>
        <div class="row align-items-end">
            <div class="col-md-4" *ngIf="form.controls.nacionalidad.value != '60'">
                <mat-form-field class="input-text" appearance="fill">
                    <mat-label class="first-capitalize">{{'tipo documento' | translate}}</mat-label>
                    <mat-select formControlName="tipoDocumento" (selectionChange)="onChangeTipoDocumento()">
                        <mat-option [value]="">--</mat-option>
                        <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                            {{tipo.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label *ngIf="form.controls.nacionalidad.value == '60'"><span class="first-capitalize">{{'dni' | translate}}</span></mat-label>
                    <mat-label *ngIf="form.controls.nacionalidad.value != '60'"><span class="first-capitalize">{{form.controls.tipoDocumento.value}}</span></mat-label>
                    <input matInput type="text" (keypress)="keypressDNI($event)" formControlName="dni" class="input-login">
                </mat-form-field>
                <div *ngIf="form.controls.dni.errors != null">
                    <mat-error *ngIf="firstSubmit && form.controls.dni.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
                    <mat-error *ngIf="form.controls.dni.errors.required == null && form.controls.dni.errors.dni_format" [innerHTML]="'error dni' | translate"></mat-error>
                    <mat-error *ngIf="form.controls.dni.errors.required == null && form.controls.dni.errors.nie_format" [innerHTML]="'error nie' | translate"></mat-error>
                </div>

            </div>
        </div>
        <div class="row align-items-end">
            <h4 class="first-capitalize">{{'residencia' | translate}}</h4>
        </div>
        <div class="row align-items-end">
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'domicilio' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="direccion1" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.direccion1.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'poblacion' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="municipio1" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.municipio1.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'provincia' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="provincia1" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.provincia1.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'codigo postal' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="codigopostal1" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.codigopostal1.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
        </div>
        <div class="row align-items-end">
            <h4 class="first-capitalize">{{'contacto' | translate}}</h4>
        </div>
        <div class="row align-items-end">
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'telefono' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="telefono1" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.telefono1.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'telefono' | translate}} 2</span></mat-label>
                    <input matInput type="text" formControlName="telefono2" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.telefono2.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
            <div class="col-md-4">
                <mat-form-field class="input-text">
                    <mat-label><span class="first-capitalize">{{'email' | translate}}</span></mat-label>
                    <input matInput type="text" formControlName="email" class="input-login">
                </mat-form-field>
                <mat-error *ngIf="firstSubmit && form.controls.email.errors?.required" [innerHTML]="'error campo required' | translate"></mat-error>
                <mat-error *ngIf="firstSubmit && form.controls.email.errors?.email" [innerHTML]="'error campo required' | translate"></mat-error>
            </div>
        </div>

    <div class="row justify-content-end">
        <button mat-raised-button color="primary-aetesys" *ngIf="!updateMode" (click)="submit()"><span class="first-capitalize">{{'boton datos personales' | translate}}</span></button>
        <button mat-raised-button color="primary-aetesys" *ngIf="updateMode" (click)="modificar()"><span class="first-capitalize">{{'modificar datos personales' | translate}}</span></button>
    </div>
</form>
</div>

