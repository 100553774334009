<app-waiting-spinner></app-waiting-spinner>
<ng-snotify class="mat-elevation-z14"></ng-snotify>

<mat-drawer-container class="drawer" [hasBackdrop]="'true'">
  <mat-drawer #drawer [mode]="'over'" [position]="'end'">
    
    <mat-nav-list class="nav-list-responsive">
      <div>
        <button mat-button (click)="cerrarDrawer()"><mat-icon mat-list-icon>arrow_forward</mat-icon></button>
      </div>
      <mat-list>
        <div mat-subheader><mat-icon mat-list-icon>library_books</mat-icon> <span class="first-capitalize label-apartado-menu">{{'cursos' | translate}}</span> </div>
        <mat-list-item *ngFor="let item of menuCurso">
              <a *ngIf="item.tipo_link == tipoLink.ROUTER_LINK" class="boton-side-menu first-capitalize" (click)="cerrarDrawer()" [routerLink]="[item.link]">
                  {{item.nombre}}
              </a>
        </mat-list-item>
        <mat-divider></mat-divider>
        <div mat-subheader><mat-icon mat-list-icon>person</mat-icon> <span class="first-capitalize label-apartado-menu">{{'login' | translate}}</span></div>
        <mat-list-item>
          <button *ngIf="!loggedin" (click)="cerrarDrawer()" class="boton-side-menu" [routerLink]="['/login']" mat-button><span class="first-capitalize"> {{'entrar-identificarse' | translate}} </span></button>
          <button *ngIf="loggedin" (click)="logout()" class="boton-side-menu" mat-button><span class="first-capitalize"> {{'cerrar sesion' | translate}} </span></button>
        </mat-list-item>
        <mat-list-item>
          <button (click)="redireccionarAula()" mat-button><span class="first-capitalize"> {{'acceso aula' | translate}} </span></button>
        </mat-list-item>
      </mat-list>
    </mat-nav-list>
  
  </mat-drawer>
  <mat-drawer-content>
      <div class="page-container fondo" style="background: url('{{fondo}}');">

              <app-nav></app-nav>

              <div class="page-content" [ngClass]="{'alpha-curso' : !fondo_default_activo}">
                <router-outlet></router-outlet>
              </div>

              <app-footer></app-footer>

      </div>
  </mat-drawer-content>
</mat-drawer-container>

