import { ListadoCursosComponent } from './components/listado-cursos/listado-cursos.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { InscripcionComponent } from './components/inscripcion/inscripcion.component';
import { DetalleCursoComponent } from './components/detalle-curso/detalle-curso.component';
import { PedidoRealizadoComponent } from './components/pedido-realizado/pedido-realizado.component';
import { AvisoLegalComponent } from './components/aviso-legal/aviso-legal.component';
import { CondicionesGeneralesComponent } from './components/condiciones-generales/condiciones-generales.component';
import { FormaPagoComponent } from './components/forma-pago/forma-pago.component';
import { PedidoRealizadoTPVComponent } from './components/pedido-realizado-tpv/pedido-realizado-tpv.component';
import { TPVOKNOKComponent } from './components/tpv-ok-nok/tpv-ok-nok.component';

const routes: Routes = [

  {
    path: '',
    component: HomeComponent
  },

  {
    path: 'cursos/:categoria',
    component: ListadoCursosComponent
  },
  {
    path: 'cursos/:categoria/:slug_categoria',
    component: ListadoCursosComponent
  },

  {
    path: 'curso/:id',
    component: DetalleCursoComponent
  },

  {
    path: 'curso/:id/:slug_curso',
    component: DetalleCursoComponent
  },

  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'inscripcion',
    component: InscripcionComponent
  },
  {
    path: 'pedido/realizado',
    component: PedidoRealizadoComponent
  },
  {
    path: 'pedido/realizado/TPV',
    component: PedidoRealizadoTPVComponent
  },
  {
    path: 'aviso-legal',
    component: AvisoLegalComponent
  },
  {
    path: 'condiciones-generales',
    component: CondicionesGeneralesComponent
  },
  {
    path: 'seleccion-forma-pago',
    component: FormaPagoComponent
  },
  {
    path: 'TPV/response/:response',
    component: TPVOKNOKComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
