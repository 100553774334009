import { CodigosDescuentoService } from 'src/app/services/codigos-descuento.service';
import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-buscador-descuentos',
  templateUrl: './buscador-descuentos.component.html',
  styleUrls: ['./buscador-descuentos.component.css']
})
export class BuscadorDescuentosComponent implements OnInit {

  public codigo_descuento_str: string = '';

  constructor(
    private codigo_descuentoSrv: CodigosDescuentoService
  ) { }

  ngOnInit(): void {
  }

  submit(){
      this.codigo_descuentoSrv.updateDescuento(this.codigo_descuento_str);
  }

}
