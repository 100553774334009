import { TipoDescuento } from "../enum/tipo-descuento.enum";

export class CommonFunctions {
    public static delay(ms:number){
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    public static procesarSexo(sexo){
        let res:string;
        switch(sexo){
            case 0:{
                res = 'V';
                break;
            }
            case 1:{
                res = 'H';
                break;
            }
            default:{
                res = '';
                break;
            }
        }

        return res;
    }

    public static obtenerPrecioDescuento(precio: number, cantidad: number, tipoDescuento: TipoDescuento): number{
        let precio_descuento: number = 0;

        switch(tipoDescuento){
            case TipoDescuento.PORCENTAJE:{
              precio_descuento = ( (precio * cantidad) / 100);
              break;
            }
            case TipoDescuento.PRECIO:{
              precio_descuento = cantidad;
              break;
            }
            case TipoDescuento.PRECIO_FIJO:{
              precio_descuento = precio - cantidad;
              break;
            }
        }
        
        return precio_descuento;
    }

    public static obtenerPrecioDescontado(precio: number, cantidad: number, tipoDescuento: TipoDescuento): number{
        let precio_descontado: number = null;
        if(tipoDescuento === TipoDescuento.PRECIO_FIJO){
            precio_descontado = cantidad;
        }else{
            precio_descontado = precio - this.obtenerPrecioDescuento(precio, cantidad, tipoDescuento);
        }

        return precio_descontado;
    }

    public static typeAlphanumericOnly(event: any){
        const pattern = /^\w*$/;
        let inputChar = event.key;
    
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
      }
}
