import { WaitingSpinnerService } from './service/waiting-spinner.service';
import { Component, OnInit, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-waiting-spinner',
  templateUrl: './waiting-spinner.component.html',
  styleUrls: ['./waiting-spinner.component.css']
})
export class WaitingSpinnerComponent implements OnInit {
  @Input() titulo: string = 'ATENCIÓN';
  @Input() subtitulo: string = '';

  public activado: boolean = false;
  public mostrarSpinner: boolean = true;
  public tipo_botonera: number = null;


  constructor(
    private service: WaitingSpinnerService,
    private router: Router
    ) {
  }

  public color: ThemePalette = 'warn';
  public mode: ProgressSpinnerMode = 'indeterminate';

  ngOnInit(): void {
    this.service.titulo.subscribe((val: string) => this.titulo = val);
    this.service.subtitulo.subscribe((val: string) => this.subtitulo = val);
    this.service.activado.subscribe((val: boolean) => this.activado = val);
  }

  cerrar(){
    this.router.navigateByUrl('aula');
  }

}
