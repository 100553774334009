import { TimerTPVService } from 'src/app/services/timer-tpv.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FondoService } from 'src/app/services/fondo.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { Pedido } from 'src/app/classes/entity-data-models/pedido';
import { EstadoPedido } from 'src/app/enum/estado-pedido.enum';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pedido-realizado-tpv',
  templateUrl: './pedido-realizado-tpv.component.html',
  styleUrls: ['./pedido-realizado-tpv.component.css']
})
export class PedidoRealizadoTPVComponent implements OnInit {

  public titulo: string = '';
  public subtitulo: string = '';
  public estado_pedido: number = null;
  public tipo_estado_pedido = EstadoPedido;
  
  private pedido_subs: Subscription = null;

  constructor(
    private fondo_srv: FondoService,
    private translate: TranslateService,
    private timer_tpv_srv: TimerTPVService,
    private pedido_srv: PedidosService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fondo_srv.setFondoDefault();

    this.pedido_subs = this.pedido_srv.pedido_insertado.subscribe((pedido: Pedido) => {
      if(pedido != null){
        this.timer_tpv_srv.startcheck(pedido);
      }else{
        this.router.navigateByUrl(AppSettings.LOGIN_REDIRECTION_ROUTE);
      }

    });

    this.pedido_subs.unsubscribe();
    
    this.timer_tpv_srv.estado_pedido.subscribe((estado: number) => {
      this.estado_pedido = estado;
      switch(this.estado_pedido){
        case this.tipo_estado_pedido.PENDIENTE:{
          this.translate.get('esperando respuesta tpv').subscribe((translation: string) => {
            this.titulo = translation;
          });
          break;
        }
        case this.tipo_estado_pedido.ACEPTADO:{
          this.translate.get('respuesta tpv ok').subscribe((translation: string) => {
            this.titulo = translation;
          });
          this.translate.get('pedido aceptado').subscribe((translation: string) => {
            this.subtitulo = translation;
          });
          break;
        }
        case this.tipo_estado_pedido.DENEGADO:{
          this.translate.get('respuesta tpv ko').subscribe((translation: string) => {
            this.titulo = translation;
          });
          this.translate.get('pedido denegado').subscribe((translation: string) => {
            this.subtitulo = translation;
          });
          break;
        }
      }
      
    });

  }

  seguirComprando(){
    this.router.navigateByUrl(AppSettings.LOGIN_REDIRECTION_ROUTE);
  }
}
