<ng-container *ngIf="loggedin">
    <div class="container" style="height: 100%;">
        <div class="row" style="height: 100%;">
            <div class="col-md-12">
                <app-selector-forma-pago [formas_pago]="formas_pago"></app-selector-forma-pago>
            </div>
        </div>
    </div>

    <div class="container-boton-realizar-compra mat-elevation-z12" [class.abrir-container-boton-compra]="abrir_boton_compra" >
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <button mat-raised-button
                                (click)="comprar()"
                                color="primary-aetesys"
                                *ngIf="abrir_boton_compra"
                                [disabled]="is_disabled_boton_compra"
                                class="boton-realizar-compra boton-full-width mat-elevation-z6" >

                        <span class="first-capitalize">{{'comprar' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
