

        <div style="position: relative;">
            <button *ngIf="errores_cesta.errors.length > 0" 
                matBadge="{{errores_cesta.errors.length}}" 
                (click)="infoErrores()" 
                matBadgeColor="accent" 
                mat-mini-fab color="error-aetesys" 
                style="position: absolute; right: 0;">
                <mat-icon>priority_hight</mat-icon>
            </button>
            <h2 mat-dialog-title>{{'cesta compra' | translate}}</h2>
        </div>

        <mat-dialog-content>
            <div class="container cesta">
                <div class="row">
                    <div class="col-md-12">
                        <app-contenido-cesta></app-contenido-cesta>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions class="actions">
            <div class="container">
                
                <div class="row">
                    <div class="col-md-12 divisor-total"></div>
                </div>
                <div class="row">
                    <div class="col-md-12 col-8">
                
                        <div class="container totales mat-elevation-z3">
                            <div class="row" *ngIf="importe_descontado > 0"> <div class="col-md-3 offset-md-9 precio">{{ importe_total | currency:'EUR'}}</div> </div>
                            <div class="row" *ngIf="importe_descontado > 0"> <div class="col-md-3 offset-md-9 precio precio-descontado">-{{ importe_descontado | currency:'EUR'}}</div> </div>
                            <div class="row"> <div class="col-md-3 offset-md-9 precio"> <span class="label-total">{{ 'total' | translate}}:</span> <span class="precio-final">{{ importe_final | currency:'EUR'}}</span> </div></div>
                        </div>

                    </div>
                    <div class="col-md-12 col-4">
                        <button mat-button mat-dialog-close color="secundary-aetesys" class="boton-cesta first-capitalize"> 
                            <mat-icon>close</mat-icon>{{'cerrar' | translate}} 
                        </button>
                        <button mat-button *ngIf="!waiting_activo" [disabled]="errores_cesta.errors.length > 0" color="primary-aetesys" class="boton-cesta" (click)="comprar()"> <span class="first-capitalize">{{'comprar' | translate}}</span> </button>
                    </div>

                </div>
                
            </div>
        </mat-dialog-actions>
