import { ValidatorFn, AbstractControl } from '@angular/forms';

export class DNIValidator {
    static DNIformat(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          // if control value is not null and is a number
          if (control.value !== null) {
                if(!this.validarDNI(control.value)){
                    return { dni_format: true };
                }
          }
          return null;
        };
    }

    static NIEformat(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          // if control value is not null and is a number
          if (control.value !== null) {
                if(!this.validarNIE(control.value)){
                    return { nie_format: true };
                }
          }
          return null;
        };
    }

    static Pasaporteformat(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          // if control value is not null and is a number
          if (control.value !== null) {
                if(!this.validarPasaporte(control.value)){
                    return { pasaporte_format: true };
                }
          }
          return null;
        };
    }

    static validarDNI ( val:string ) {
        var dni: string = val.toUpperCase();
        var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
        var letter = dni_letters.charAt( parseInt( dni.substr(0, 8) ) % 23 );
        
        return letter == dni.charAt(8);
    }
    
    static validarNIE( val:string ) {
        const nie:string = val.toUpperCase();
        // Change the initial letter for the corresponding number and validate as DNI
        var nie_prefix = nie.charAt( 0 );
    
        switch (nie_prefix) {
          case 'X': nie_prefix = '0'; break;
          case 'Y': nie_prefix = '1'; break;
          case 'Z': nie_prefix = '2'; break;
        }
    
        return this.validarDNI( nie_prefix + nie.substr(1) );
    
    }

    static validarPasaporte(val:string) {
        var regsaid = /[a-zA-Z]{3}[0-9]{6}$/;
 
        if(regsaid.test(val.toUpperCase()) == false)
        {
            return false;
        }
        else
        {
            return true;
        }
    }
    

}
