<div class="container">
  <app-aviso-web></app-aviso-web>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3 col-card-categoria" *ngFor="let categoria of categorias">
          <app-card-categoria [categoria]="categoria"></app-card-categoria>
        </div>
      </div>
    </div>
  </div>
</div>
