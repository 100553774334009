import { ProfileService } from 'src/app/services/auth/profile/profile.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { SnotifyService } from 'ng-snotify';
import { TokenService } from 'src/app/services/auth/token/token.service';
import { Subscription, timer } from 'rxjs';
import { CestaService } from 'src/app/services/cesta.service';
import { MatDialog } from '@angular/material/dialog';
import { CestaDialog } from '../cesta/cesta.component';
import { FondoService } from 'src/app/services/fondo.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  public firstSubmit: boolean = false;

  public loggedin: boolean = false;

  private timerID: Subscription = null;
  private authSub: Subscription = null;
  private autoAperturaSub: Subscription = null;

  disabled = false;

  public form: FormGroup = new FormGroup({
    usuario: new FormControl('',[
      Validators.required
    ] ),
    contrasena: new FormControl('', Validators.required),
    idempresa: new FormControl(AppSettings.id_empresa)
  });

  constructor(
      private authServ: AuthService,
      private sNotify: SnotifyService,
      private Token: TokenService,
      private router: Router,
      private profile: ProfileService,
      private cestaSrv: CestaService,
      private dialog: MatDialog,
      private fondoSrv: FondoService
      ) {

  }
  ngOnDestroy(): void {
    if(this.authSub != null){
      this.authSub.unsubscribe();
    }

    if(this.autoAperturaSub != null){
      this.autoAperturaSub.unsubscribe();
    }

  }

  ngOnInit(): void {
    this.authSub = this.authServ.getAuthStatus().subscribe(
      res => {
        this.loggedin = res;
        if(res){
          this.autoAperturaSub = this.cestaSrv.auto_apertura.subscribe(
            (auto_apertura: boolean) => {
              if(auto_apertura){
                this.cestaSrv.desactivarAutoApertura();
                this.dialog.open(CestaDialog);
              }
              this.autoAperturaSub?.unsubscribe();
            }
          );

          let timerObj = timer(1500, 1000);
          this.timerID = timerObj.subscribe(
            val => {
              this.timerID.unsubscribe();
              this.profile.updateMe();
              this.router.navigateByUrl(AppSettings.LOGIN_REDIRECTION_ROUTE);
            }
          );
        }

      }
    );

    this.fondoSrv.setFondoDefault();

  }

  public submit(){
    this.disabled=true
    this.firstSubmit = true;
    if(this.form.status === 'VALID'){
      let request = this.form.value;
      this.authServ.login(request).subscribe(
        data => this.handleResponse(data),
        error => this.handleError(error)
        );
    }else{
      this.disabled=false
    }/**/
  }

  handleResponse(data) {
    this.Token.handle(data.data.api_token);
    this.authServ.changeAuthStatus(true);
  }


  handleError(error) {
      this.disabled=false
      this.sNotify.error(error.error.message, AppSettings.sNotifyCommonConf);
  }
}
