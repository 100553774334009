export class Alumno {
    public id: number = null;
    public idegs: number = null;
    public nombre: string = null;
    public apellidos: string = null;
    public apellido1: string = null;
    public apellido2: string = null;
    public dni: string = null;
    public sexo: number = null;
    public nacimiento: string = null;
    public nacionalidad: string = null;
    public profesion: string = null;
    public telefono1: string = null;
    public telefono2: string = null;
    public email: string = null;
    public centrotrabajo: string = null;
    public direccion1: string = null;
    public municipio1: string = null;
    public provincia1: string = null;
    public codigopostal1: string = null;
    public direccion2: string = null;
    public municipio2: string = null;
    public provincia2: string = null;
    public codigopostal2: string = null;
    public direccionenvio: string = null;
    public alta: number = null;
    public codigo: string = null;
    public activo: number = null;
    public ip: string = null;
    public borrado: number = null;
}