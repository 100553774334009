import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Encriptacion } from 'src/app/classes/encriptacion';
import { CodigoDescuento } from 'src/app/classes/entity-data-models/codigo-descuento';
import { Curso } from 'src/app/classes/entity-data-models/curso';
import { Router } from '@angular/router';
import { CommonFunctions } from 'src/app/classes/common-functions';
import { CestaService } from 'src/app/services/cesta.service';

@Component({
  selector: 'app-card-curso',
  templateUrl: './card-curso.component.html',
  styleUrls: ['./card-curso.component.css']
})
export class CardCursoComponent implements OnChanges {
  @Input() curso: Curso = null;
  @Input() descuento: CodigoDescuento = null;

  public cursoVista: Curso = new Curso();

  public precio: number = null;
  public precio_descuento: number = null;

  private enc = new Encriptacion();

  constructor(
    private router: Router,
    private Cesta: CestaService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.curso != null){
        this.cursoVista = new Curso(this.curso);
      }

      

      if(this.descuento != null){
        let tipodescuento: number = +this.enc.desencriptar(this.descuento.tipo);
        let cantidad: number = this.descuento.cantidad;

        this.precio_descuento = CommonFunctions.obtenerPrecioDescuento(this.curso?.precioonline, cantidad, tipodescuento);
        this.precio = CommonFunctions.obtenerPrecioDescontado(this.curso?.precioonline, cantidad, tipodescuento);

      }
  }

  public redireccionarFichaCurso(){
      if(!this.existeItemEnCesta()){
        this.router.navigateByUrl('/curso/' + this.cursoVista.idtrcurscat + '/' + this.cursoVista.nombresec);
      }
  }

  public existeItemEnCesta(): boolean{
    return this.Cesta.existeItem(this.curso);
  }


}
