<mat-list>
    <div mat-subheader><span class="first-capitalize">{{'contacto' | translate}}</span></div>
    <mat-list-item>
      <mat-icon mat-list-icon>phone</mat-icon>
      <div mat-line class="first-capitalize">{{'telefono' | translate}}</div>
      <div mat-line> {{telefono}} </div>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
      <mat-icon mat-list-icon>email</mat-icon>
      <div mat-line class="first-capitalize">{{'email' | translate}}</div>
      <div mat-line> 
            <a href="mailto:{{email}}">{{email}}</a>
      </div>
    </mat-list-item>
</mat-list>