import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from '../app-settings';
import { Pedido } from '../classes/entity-data-models/pedido';
import { TokenService } from './auth/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  private _pedido_insertado = new BehaviorSubject(<Pedido> null);

  public pedido_insertado = this._pedido_insertado.asObservable();

  constructor(
    private http: HttpClient,
    private Token: TokenService
  ) { }

  setPedidoInsertado(value: Pedido){
    this._pedido_insertado.next(value);
  }

  find(id_pedido: number){
    let request = {
      id_pedido: id_pedido,
      token: this.Token.get()
    };

    return this.http.post(AppSettings.API_ENDPOINT + '/pedidos/find/by/id', request);
  }
}
