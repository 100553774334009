import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-contacto-bottom-sheet',
  templateUrl: './contacto-bottom-sheet.component.html',
  styleUrls: ['./contacto-bottom-sheet.component.css']
})
export class ContactoBottomSheetComponent implements OnInit {

  public telefono: string = "(+34) 666 583 230  (L-V 10-14h)";
  public email: string = "info@bacformacion.com";

  constructor(private _bottomSheetRef: MatBottomSheetRef<ContactoBottomSheetComponent>) {}
  ngOnInit(): void {

  }

}
