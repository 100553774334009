import { Component, Input, OnInit } from '@angular/core';
import { TipoPago } from 'src/app/classes/entity-data-models/tipo-pago';
import { CestaService } from 'src/app/services/cesta.service';

@Component({
  selector: 'app-selector-forma-pago',
  templateUrl: './selector-forma-pago.component.html',
  styleUrls: ['./selector-forma-pago.component.css']
})
export class SelectorFormaPagoComponent implements OnInit {
  @Input() formas_pago: TipoPago[] = [];

  constructor(
    private cesta_srv: CestaService
  ) { }

  ngOnInit(): void {
  }

  setFormaPago(forma_pago_seleccionada: TipoPago){
    this.cesta_srv.setFormaPago(forma_pago_seleccionada);
  }

}
