import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';


export class PasswordValidator {
    static passwordRepeat(group: FormGroup) {
       
            let pass = group.get('password').value;
            let password_confirm = group.get('password_confirm').value;
            return pass === password_confirm ? null : { confirmation: true }  ;
    }
}
