import { CodigoDescuento } from "./codigo-descuento";
import { Curso } from "./curso";

export class ItemCesta {

    public curso: Curso = null;
    public codigo_descuento: CodigoDescuento = null;
    public cursosAplicables: Array<CodigoDescuento>;
    constructor(curso: Curso = null, codigo_descuento: CodigoDescuento = null, cursosAplicables=[]){
        this.curso = curso;
        this.codigo_descuento = codigo_descuento;
        this.cursosAplicables = cursosAplicables;
    }
}
