import { MenuCursosService } from 'src/app/services/menu-cursos.service';
import { CategoriascursosService } from 'src/app/services/categoriascursos.service';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CategoriaCurso } from 'src/app/classes/entity-data-models/categoria-curso';
import { FondoService } from 'src/app/services/fondo.service';
import { ItemMenuCurso } from 'src/app/classes/entity-data-models/item-menu-curso';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TipoLink } from 'src/app/enum/tipo-link.enum';
import { AvisoWebComponent } from 'src/app/components/aviso-web/aviso-web.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  public categorias: CategoriaCurso[] = null;

  private translateSub: Subscription = null;

  constructor(
    private categoriasServ: CategoriascursosService,
    private fondoSrv: FondoService,
    private menuSrv: MenuCursosService,
    private translate: TranslateService,
  ) { }

  ngOnDestroy(): void {
    if (this.translateSub != null) {
      this.translateSub.unsubscribe();
    }
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fondoSrv.setFondoDefault();
    });
  }


  ngOnInit(): void {
    this.categoriasServ.categorias.subscribe((cats: CategoriaCurso[]) => { this.categorias = cats });
    this.translateSub = this.translate.get('categorias').subscribe((categoria_nombre: string) => {
      let item_menu: ItemMenuCurso = new ItemMenuCurso(categoria_nombre, '/', TipoLink.ROUTER_LINK);
      this.menuSrv.delete();
      this.menuSrv.push(item_menu);
    });
  }

}
