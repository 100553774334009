<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="titulo-selector-pago">
                <h1>{{'seleccione forma pago' | translate}}</h1>
            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-md" *ngFor="let forma_pago of formas_pago"> 
            <app-item-forma-pago [forma_pago]="forma_pago" (forma_pago_seleccionada)="setFormaPago($event)"></app-item-forma-pago>
        </div>
    
    </div>
</div>
