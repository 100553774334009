import { TipoPagoPedido } from 'src/app/enum/tipo-pago-pedido.enum';

export class TipoPago {
    private _id: number             = null;
    private _nombre: string         = null;
    private _descripcion: string    = null;
    private _imagen: string         = null;


    constructor(id: number, nombre: string = null, descripcion: string = null){
        this.id             = id;
        this.nombre         = nombre;
        this.descripcion    = descripcion;
    }


    /**
     * Getter id
     * @return {number             }
     */
	public get id(): number              {
		return this._id;
	}

    /**
     * Getter nombre
     * @return {string         }
     */
	public get nombre(): string          {
		return this._nombre;
	}

    /**
     * Getter descripcion
     * @return {string    }
     */
	public get descripcion(): string     {
		return this._descripcion;
	}

    /**
     * Setter id
     * @param {number             } value
     */
	public set id(value: number             ) {
		this._id = value;
	}

    /**
     * Setter nombre
     * @param {string         } value
     */
	public set nombre(value: string         ) {
		this._nombre = value;
	}

    /**
     * Setter descripcion
     * @param {string    } value
     */
	public set descripcion(value: string    ) {
		this._descripcion = value;
	}
    
        /**
     * Getter imagen
     * @return {string         }
     */
	public get imagen(): string          {
		return this._imagen;
	}

    /**
     * Setter imagen
     * @param {string         } value
     */
	public set imagen(value: string         ) {
		this._imagen = value;
	}

}