import { TipoLink } from 'src/app/enum/tipo-link.enum';
export class ItemMenuCurso {

    private _nombre: string = null;
    private _link: string = null;
    private _tipo_link: TipoLink = null;

    constructor(nombre: string = null, link: string = null, tipo_link: TipoLink = null){
        this._nombre    = nombre;
        this._link      = link;
        this._tipo_link = tipo_link;
    }

    /**
     * Getter nombre
     * @return {string }
     */
	public get nombre(): string  {
		return this._nombre;
	}

    /**
     * Getter link
     * @return {string }
     */
	public get link(): string  {
		return this._link;
	}

    /**
     * Getter tipo_link
     * @return {TipoLink }
     */
	public get tipo_link(): TipoLink  {
		return this._tipo_link;
	}

    /**
     * Setter nombre
     * @param {string } value
     */
	public set nombre(value: string ) {
		this._nombre = value;
	}

    /**
     * Setter link
     * @param {string } value
     */
	public set link(value: string ) {
		this._link = value;
	}

    /**
     * Setter tipo_link
     * @param {TipoLink } value
     */
	public set tipo_link(value: TipoLink ) {
		this._tipo_link = value;
	}

}
