import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { TipoPago } from 'src/app/classes/entity-data-models/tipo-pago';
import { AppSettings } from '../app-settings';
import { TipoPagoPedido } from '../enum/tipo-pago-pedido.enum';

@Injectable({
  providedIn: 'root'
})
export class TiposPagoService {

  private _tipos_pago = new BehaviorSubject(<TipoPago[]> []);
  public tipos_pago = this._tipos_pago.asObservable();

  constructor(
    private translate: TranslateService
  ) { }

  public inicializarTiposPago(){
    let tipos_pago: TipoPago[] = [];
    tipos_pago[0] = new TipoPago(TipoPagoPedido.TPV);
    tipos_pago[1] = new TipoPago(TipoPagoPedido.TRANSFERENCIA);

    this.translate.get('nombre tipo pago TPV').subscribe((translation: string) => tipos_pago[0].nombre = translation);
    this.translate.get('descripcion tipo pago TPV').subscribe((translation: string) => tipos_pago[0].descripcion = translation);

    this.translate.get('nombre tipo pago transferencia').subscribe((translation: string) => tipos_pago[1].nombre = translation);
    this.translate.get('descripcion tipo pago transferencia').subscribe((translation: string) => tipos_pago[1].descripcion = translation);

    tipos_pago[0].imagen = AppSettings.LOGO_PATH + '/forma_pago_tarjeta.png';
    tipos_pago[1].imagen = AppSettings.LOGO_PATH + '/forma_pago_transferencia.png';

    this._tipos_pago.next(tipos_pago);
  }
}
