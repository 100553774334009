import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tpv-ok-nok',
  templateUrl: './tpv-ok-nok.component.html',
  styleUrls: ['./tpv-ok-nok.component.css']
})
export class TPVOKNOKComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.close();
  }

}
