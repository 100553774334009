<div class="container">
    <div class="row">
        <div class="col-md-12">
            <mat-card class="mat-elevation-z12">
                <mat-card-header>
                  <mat-card-title>{{titulo}}</mat-card-title>
                  <mat-card-subtitle>{{subtitulo}}</mat-card-subtitle>
                </mat-card-header>

                <mat-icon *ngIf="estado_pedido == tipo_estado_pedido.PENDIENTE" class="icon-large mat-secundary-aetesys mat-elevation-z3">sync</mat-icon>
                <mat-icon *ngIf="estado_pedido == tipo_estado_pedido.ACEPTADO" class="icon-large mat-primary-aetesys mat-elevation-z3">done</mat-icon>
                <mat-icon *ngIf="estado_pedido == tipo_estado_pedido.DENEGADO" class="icon-large mat-error-aetesys mat-elevation-z3">error</mat-icon>

                <mat-card-content>
                  
                </mat-card-content>
                <mat-card-actions>
                    <mat-progress-bar *ngIf="estado_pedido == tipo_estado_pedido.PENDIENTE" mode="indeterminate" color="primary-aetesys"></mat-progress-bar>
                    <button mat-raised-button *ngIf="estado_pedido != tipo_estado_pedido.PENDIENTE" color="primary-aetesys" class="boton-full-width" (click)="seguirComprando()">
                      <span *ngIf="estado_pedido == tipo_estado_pedido.ACEPTADO" class="first-capitalize">{{'seguir comprando' | translate}}</span>
                      <span *ngIf="estado_pedido == tipo_estado_pedido.DENEGADO" class="first-capitalize">{{'volver intentar' | translate}}</span>
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>