import { RedireccionTPV } from 'src/app/classes/redireccion-tpv';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { AppSettings } from 'src/app/app-settings';
import { ItemCesta } from 'src/app/classes/entity-data-models/item-cesta';
import { Pedido } from 'src/app/classes/entity-data-models/pedido';
import { TipoPago } from 'src/app/classes/entity-data-models/tipo-pago';
import { ResponseApi } from 'src/app/classes/response-api';
import { TipoPagoPedido } from 'src/app/enum/tipo-pago-pedido.enum';
import { TokenService } from 'src/app/services/auth/token/token.service';
import { CestaService } from 'src/app/services/cesta.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { FondoService } from 'src/app/services/fondo.service';
import { PedidosService } from 'src/app/services/pedidos.service';
import { TiposPagoService } from 'src/app/services/tipos-pago.service';
import { WaitingSpinnerService } from '../waiting-spinner/service/waiting-spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forma-pago',
  templateUrl: './forma-pago.component.html',
  styleUrls: ['./forma-pago.component.css']
})
export class FormaPagoComponent implements OnInit, OnDestroy {

  public formas_pago: TipoPago[] = [];
  public abrir_boton_compra: boolean = false;
  public loggedin: boolean = false;
  public id_forma_pago: number = null;
  public is_disabled_boton_compra: boolean = false;

  private i18n_confirmar_compra: string = 'confirmar compra';
  private i18n_si: string = 'si';
  private i18n_mas_tarde: string = 'mas tarde';

  private token_subscription: Subscription = new Subscription();
  private cesta_subscription: Subscription = new Subscription();


  constructor(
    private fondo_service: FondoService,
    private tipos_pago_service: TiposPagoService,
    private cesta_srv: CestaService,
    private router: Router,
    private Token: TokenService,
    private sNotify: SnotifyService,
    private translate: TranslateService,
    private waiting_spinner: WaitingSpinnerService,
    private error_srv: ErrorService,
    private pedido_srv: PedidosService
  ) { }

  ngOnDestroy(): void {
    this.token_subscription.unsubscribe();
    this.cesta_subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.translate.get(this.i18n_confirmar_compra).subscribe((translation: string) => this.i18n_confirmar_compra = translation);
    this.translate.get(this.i18n_si).subscribe((translation: string) => this.i18n_si = translation);
    this.translate.get(this.i18n_mas_tarde).subscribe((translation: string) => this.i18n_mas_tarde = translation);

    this.token_subscription = this.Token.checkToken().subscribe(
      (res: ResponseApi) => {
            this.loggedin = res.data.response;
            this.cesta_subscription.add(this.cesta_srv.get().subscribe((items: ItemCesta[]) => {
                let redireccionar: boolean = false;

                if(items?.length > 0){
                  this.cesta_subscription.add(this.cesta_srv.getItemsErroneos().subscribe((items_erroneos: ItemCesta[]) => redireccionar = (items_erroneos.length > 0)));
                }else{
                  redireccionar = true;
                }

                if(redireccionar){
                  this.router.navigateByUrl(AppSettings.LOGIN_REDIRECTION_ROUTE);
                }
            }));
      },
      error => this.router.navigateByUrl(AppSettings.LOGIN_ROUTE)
      );

    this.fondo_service.setFondoDefault();
    this.tipos_pago_service.tipos_pago.subscribe((tps: TipoPago[]) => this.formas_pago = tps);
    this.cesta_srv.id_forma_pago.subscribe((id_fp: number) => {
      this.abrir_boton_compra = (id_fp != null);
      this.id_forma_pago = id_fp;
    });
  }

  public comprar(){
    this.is_disabled_boton_compra = true;
    this.sNotify.confirm(this.i18n_confirmar_compra, AppSettings.getConfirmConf([
      {text: this.i18n_si, action: () => {
        this.enviarCesta()
      }},
      {text: this.i18n_mas_tarde, action: () => this.is_disabled_boton_compra = false},
    ]));/**/

  }

  private enviarCesta(){
    this.waiting_spinner.activar();

    this.cesta_srv.enviarCesta().subscribe(
      (res: ResponseApi) => this.handleEnvioCesta(res),
      error => this.errorEnvioCesta(error)
    );/**/
  }

  private handleEnvioCesta(res: ResponseApi){
    this.waiting_spinner.desactivar();
    if(res){
      let metodo_pago: number = this.id_forma_pago;

      let pedido_introducido: Pedido = res.data.pedido;
      this.pedido_srv.setPedidoInsertado(pedido_introducido);

      this.cesta_srv.vaciarCesta();

      switch(metodo_pago){
        case TipoPagoPedido.TRANSFERENCIA:{
          this.translate.get('compra exito').subscribe((traduction: string) => {
            this.sNotify.success(traduction, AppSettings.sNotifyCommonConf);
          });
          this.router.navigateByUrl('pedido/realizado');
          break;
        }
        case TipoPagoPedido.TPV:{
          this.router.navigateByUrl('pedido/realizado/TPV');
          this.redireccionarTPV(pedido_introducido);
        }
      }

    }
  }

  private redireccionarTPV(pedido: Pedido){
      RedireccionTPV.redireccionarTPV_BAC(pedido.id);
  }


  private errorEnvioCesta(error){
    this.waiting_spinner.desactivar();
    this.error_srv.errorEnvioCesta();
  }

}
